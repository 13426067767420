import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/aaron/Apps/fresh-pets/src/components/mdx-layout/mdx-layout.js";
import SEO from '../components/seo/seo.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title='Services' description='From Clipping services to Ultrasonic Teeth Cleaning, we’ve got you covered.' keywords={['Fresh', 'Pets', 'Services']} mdxType="SEO" />
    <h1>{`Services`}</h1>
    <p>{`We want your dog to look fabulous - therefore all pets require bathing before any clipping and trimming, with the exception of "While You Wait" services and Hand Stripping.`}</p>
    <h2>{`While You Wait`}</h2>
    <p>{`No pre booked appointment needed however, calling ahead will reduce waiting time.`}</p>
    <ul>
      <li parentName="ul">{`Nail trimming`}</li>
      <li parentName="ul">{`Feet tidy plus nail trim`}</li>
      <li parentName="ul">{`Eye area trim`}</li>
      <li parentName="ul">{`Sanitary trim`}</li>
    </ul>
    <h2>{`Puppy Grooms`}</h2>
    <p>{`Puppys are welcomed for their first visit to the salon as soon as they have had both sets of vaccinations, getting them used to the salon environment and giving them positive grooming experiences at this age prepares them well for a lifetime of grooming. `}</p>
    <p>{`A puppy groom includes`}</p>
    <ul>
      <li parentName="ul">{`Bath`}</li>
      <li parentName="ul">{`Blow dry`}</li>
      <li parentName="ul">{`Brush`}</li>
      <li parentName="ul">{`Trimming of face, bottom, feet and nails`}</li>
    </ul>
    <p>{`We recommend 2-3 puppy grooms before moving on to a full groom. `}</p>
    <h2>{`Teeth Cleaning`}</h2>
    <p>{`For the removal of mild to moderate plaque build up, to freshen breath and maintain good oral heath.`}</p>
    <p>{`This method of teeth cleaning uses no vibration or noise. Each session is up to half an hour for plaque removal and 15 minutes per maintenance session - please speak to a member of the Fresh Pets team about booking a consultation.`}</p>
    <h2>{`Bath and Brush/Tidy`}</h2>
    <p>{`For dogs who are attending regular full groom appointments, this service is great for dogs who need a freshen up between grooms or have high maintenance coats and need a bit of extra brushing. `}</p>
    <p>{`Having a clean, conditioned and thoroughly brushed coat can really ease the pressure of at home coat care. `}</p>
    <p>{`A nail trim is always included in all grooming services but you can also upgrade to a Bath and Tidy service by adding a face, feet and sanitary trim.`}</p>
    <h2>{`Full Groom`}</h2>
    <p>{`Whether you're looking for a Breed Standard Style, a fully Hand Scissored trim, a complete All Over clip off or something in between we've got it covered - includes bath and claw trim.`}</p>
    <h2>{`Hand Stripping`}</h2>
    <p>{`Breed dependable, hand removal of coat in required areas including trimming to finish and a bath which can be completed within the same appointment or on a different day for dogs who have reactive/sensitive skin.`}</p>
    <p>{`We are unlikely to be able to hand strip dogs who have been spayed/neutered or who have previously clipped even if they are a breed which would typically be hand stripped as both of these actions change the character of the coat.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      